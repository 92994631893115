.hidden {
  display: none;
}

.textAlignRight {
  text-align: right;
}

/* MATERIAL UI FIXES */
.gridCeilingMargin12 {
  margin-top:12px;
}



/*main heading on each page - needs to be updated*/
.LinkGroupLayout_ContainerTitle {
  font-size: 30px;
}
.sidebarSubNav_headlineSmall {
  font-size: 12px;
  text-align:center;
}
.sidebarSubNav_headlineLarge {
  font-size: 16px;
  text-align:center;
}
.sidebarSubNav_headlineTiny {
  font-size: 11px;
  text-align:center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom:4px;
}
.sidebarSubNav_NavLinkSectionHeader {
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 4px;
  padding-top: 10px;
  font-weight: bold;
  color: #3F3F3F;
}

/****** CONTENT WRAPPERS ******/
.contentPad10 {
  padding:10px;
}
.contentPad20 {
  padding:20px;
}



.pageWrapper {
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  width:100%;
  padding: 0;
  flex-direction: column;
}

.noLinkStyles {
  text-decoration: none;
}



.commonStylesTest {
  background-color: black;
  color: #fff;
}

/******* BASICS ******/
.fullHeight {
  height:100%;
}

.fullwidth {
  width:100%;
}




.contentTitleBar {
  padding-bottom:20px;
}
.gridItem {
  padding-top:0 !important;
  padding-bottom:0 !important;
}




/* FORMS & STYLES */
.formSectionTitle {
  font-size: 18px;
  padding-top:40px;
  padding-bottom:15px;
  color: #444;
}
.stripeFormSectionOptions {
  font-size: 12px;
  color: #444;
}

.color_primary_dark {
  color: #444;
}
.color_primary_light {
  color: #fff;
}
.color_primary_medium {
  color: rgba(0, 0, 0, 0.54);
}

.color_primary_medium {
  color: rgba(0, 0, 0, 0.54);
}

.colour_primary {
  color: #2980b9;
}
.colour_secondary {
  color: #34495e;
}
.colour_success {
  color: #16a085;
}
.colour_danger {
  color: #c0392b;
}
.colour_warning {
  color: #e67e22;
}
.colour_info {
  color: #8e44ad;
}
.colour_white {
  color: #fff;
}



.heading_h1 {
  font-size: 32px;
  padding-top:40px;
  padding-bottom:15px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h2 {
  font-size: 24px;
  padding-top:30px;
  padding-bottom:15px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h3 {
  font-size: 18px;
  padding-top:30px;
  padding-bottom:15px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h4 {
  font-size: 16px;
  padding-top:25px;
  padding-bottom:8px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h5 {
  font-size: 14px;
  padding-top:25px;
  padding-bottom:8px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h6 {
  font-size: 12px;
  padding-top:25px;
  padding-bottom:8px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}
.heading_h7 {
  font-size: 11px;
  padding-top:25px;
  padding-bottom:8px;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  font-weight: normal;
}


.text_s20 {
  font-size: 20px;
}
.text_s16 {
  font-size: 16px;
}

.text_h1 {
  font-size: 32px;
}
.text_h2 {
  font-size: 24px;
}
.text_h3 {
  font-size: 18px;
}
.text_h4 {
  font-size: 16px;
}
.text_h5 {
  font-size: 14px;
}
.text_h6 {
  font-size: 12px;
}
.text_h7 {
  font-size: 11px;
}

.fontWeightBold {
  font-weight: bold;
}

.bgWhite {
  background-color:#fff;
}

.wordWrapBreak {
  word-break: break-all;
}

.pad10 {
  padding:10px;
}
.pad15 {
  padding:15px;
}
.pad20 {
  padding:20px;
}
.pad25 {
  padding:25px;
}
.pad30 {
  padding:30px;
}

.padB10 {
  padding-bottom:10px;
}
.padB15 {
  padding-bottom:15px;
}
.padB20 {
  padding-bottom:20px;
}
.padB25 {
  padding-bottom:25px;
}
.padB30 {
  padding-bottom:30px;
}

.padT10 {
  padding-top:10px;
}
.padT15 {
  padding-top:15px;
}
.padT20 {
  padding-top:20px;
}
.padT25 {
  padding-top:25px;
}
.padT30 {
  padding-top:30px;
}

.padR5 {
  padding-right:5px;
}
.padR10 {
  padding-right:10px;
}
.padR15 {
  padding-right:15px;
}
.padR20 {
  padding-right:20px;
}
.padR25 {
  padding-right:25px;
}
.padR30 {
  padding-right:30px;
}

.padL5 {
  padding-left:5px;
}
.padL10 {
  padding-left:10px;
}
.padL15 {
  padding-left:15px;
}
.padL20 {
  padding-left:20px;
}
.padL25 {
  padding-left:25px;
}
.padL30 {
  padding-left:30px;
}

.padLR2 {
  padding-left:2px;
  padding-right:2px;
}
.padLR5 {
  padding-left:5px;
  padding-right:5px;
}
.padLR10 {
  padding-left:10px;
  padding-right:10px;
}

.marB10 {
  margin-bottom:10px;
}
.marB15 {
  margin-bottom:15px;
}
.marB20 {
  margin-bottom:20px;
}
.marB25 {
  margin-bottom:25px;
}
.marB30 {
  margin-bottom:30px;
}

.marT10 {
  margin-top:10px;
}
.marT15 {
  margin-top:15px;
}
.marT20 {
  margin-top:20px;
}
.marT25 {
  margin-top:25px;
}
.marT30 {
  margin-top:30px;
}

.marR10 {
  margin-right:10px;
}
.marR15 {
  margin-right:15px;
}
.marR20 {
  margin-right:20px;
}
.marR25 {
  margin-right:25px;
}
.marR30 {
  margin-right:30px;
}

.marL10 {
  margin-left:10px;
}
.marL15 {
  margin-left:15px;
}
.marL20 {
  margin-left:20px;
}
.marL25 {
  margin-left:25px;
}
.marL30 {
  margin-left:30px;
}

.marLR2 {
  margin-left:2px;
  margin-right:2px;
}
.marLR5 {
  margin-left:5px;
  margin-right:5px;
}
.marLR10 {
  margin-left:10px;
  margin-right:10px;
}

.borderTLight {
  border-top: 1px solid #bbb;
}
.borderRLight {
  border-right: 1px solid #bbb;
}
.borderBLight {
  border-bottom: 1px solid #bbb;
}
.borderLLight {
  border-left: 1px solid #bbb;
}






/* COMMON LIST STYLES */
.listStyleNone {
  list-style: none;
}

/* COMMON STYLES */
.flex {
  display:flex;
}
.flexColumn {
  flex-direction: column;
}
.flexRow {
  flex-direction: row;
}
.flexJustifySpaceBetween {
  justify-content: space-between;
}
.flexJustifySpaceAround {
  justify-content: space-around;
}
.flexJustifyFlexStart{
  justify-content: flex-start;
}
.flexJustifyFlexEnd{
  justify-content: flex-end;
}

.flexAlignBaseline {
  align-items: baseline;
}
.flexGrow {
  flex-grow: 1;
}
.flexVerticalAlignCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}






.shcMaterialTextField {
  /*border: 1px #e6ebf1 solid !important;*/
  background-color: #fefefe;
  background-clip: padding-box !important;
  border-radius: 5px !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  word-break: normal !important;
  box-shadow: 0 1px 3px 0 #e6ebf1 !important;
}


.shcMaterialTextField label {
  padding-left: 10px;
  /*transform: translate(0, 8.5px) scale(0.75) !important;*/
  font-family: "Poppins", "Helvetica", "Arial", sans-serif !important;
  color: #999 !important;
}
.shcMaterialTextField input {
  padding-left: 10px;
  width:100%;
}
.shcMaterialTextField p[id$="-helper-text"] {
  padding-left: 10px;
  position: absolute;
  bottom: -16px;
}




/****** CALC FUNCTIONS ******/
.fullwidthMinus150px {
  width: calc(100% - 150px);
}